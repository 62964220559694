const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;

export const postProcessScript = async (data, setError) => {
    const url = `${MAIN_API_URL}/post_process_script`;
    try {
        // API call to process script scenes
        const scenes_text = data.script.scenes.map(scene => scene.scene_text);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(scenes_text),
        });

        if (!response.ok) {
            // Handle network errors or non-200 responses here
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const processedScript = await response.json();
        const scriptJsonWithCameraShots = processedScript.script_json;
        const scriptText = processedScript.script_text;
        return { scriptJsonWithCameraShots, scriptText }; // Return as an object
    } catch (error) {
        setError('Failed to create package. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return []; // Return an empty array in case of an error
    }
};
