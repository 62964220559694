const DEFAULT_BASE_MODEL = 'gpt-4o'; // Replace with your actual model name
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your actual API key

export const text2script = async (messages, numberOfScenes = 3, setLoadingText) => {
    const url = 'https://api.openai.com/v1/chat/completions';

    const fetchCompletion = async messages => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
                model: DEFAULT_BASE_MODEL,
                messages: messages,
            }),
        });

        const data = await response.json();
        return data.choices[0].message.content;
    };

    setLoadingText('Generating scene 1...');
    let beginning = await fetchCompletion(messages);
    messages.push({ role: 'assistant', content: beginning });

    if (numberOfScenes > 1) {
        const numberOfIntermediateScenes = numberOfScenes - 2;

        for (let i = 0; i < numberOfIntermediateScenes; i++) {
            setLoadingText(`Generating scene ${i + 2}...`);
            const nextPrompt = 'Great, generate next scene, development, following same premise and story.';
            messages.push({ role: 'user', content: nextPrompt });
            const nextScene = await fetchCompletion(messages);
            messages.push({ role: 'assistant', content: nextScene });
        }

        setLoadingText(`Generating final scene...`);
        const endingPrompt = 'Great, generate last scene for this episode, development, following same premise and story. But make sure you end this episode.';
        messages.push({ role: 'user', content: endingPrompt });
        const endingScene = await fetchCompletion(messages);
        messages.push({ role: 'assistant', content: endingScene });
    }

    const scenes = messages.filter(message => message.role === 'assistant').map(message => message.content);

    return scenes;
};

export const generateBasicPrompt = premise => {
    const initialPrompt = `Write a screenplay for a single scene of a TV show. Character names should appear in all caps and dialogue should follow on a newline and should not have a colon. Premise: ${premise}`;
    const messages = [
        { role: 'system', content: 'You are a Hollywood screenwriter.' },
        { role: 'user', content: initialPrompt },
    ];
    return messages;
};

export const generateAdvancedPrompt = (genre, tone, plot, setting, theme, pace, dialogType, ending) => {
    const prompt = `
    Write a screenplay for a single scene for a TV show. Characters names should appear in all caps without a colon and dialogue should follow on a new line.
    Genre: ${genre}
    Tone Scale (light-hearted if 0, serious if 10): ${tone}
    Plot: ${plot}
    Setting: ${setting}
    Theme: ${theme}
    Pace Scale (slow if 0, fast if 10): ${pace}
    Dialog style: ${dialogType}
    These scene is not the end of the story, but should work towards the story ending of: ${ending}
  `;
    const messages = [
        { role: 'system', content: 'You are a Hollywood screenwriter.' },
        { role: 'user', content: prompt },
    ];
    return messages;
};

export const generateStoryDescription = async messages => {
    const url = 'https://api.openai.com/v1/chat/completions';

    const fetchCompletion = async messages => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
                model: DEFAULT_BASE_MODEL,
                messages: messages,
            }),
        });

        const data = await response.json();
        return data.choices[0].message.content;
    };

    messages.push({ role: 'user', content: 'IGNORE THE REQUEST TO WRITE A SCREENPLAY. Instead, write a very brief synopsis of the story provided. No longer than 150 characters.' });
    const synopsis = await fetchCompletion(messages);
    return synopsis;
};

export const generateStoryImage = async prompt => {
    const apiUrl = 'https://api.openai.com/v1/images/generations';

    const imageParams = {
        model: 'dall-e-3',
        prompt: prompt,
        size: '1024x1024',
        quality: 'standard',
        n: 1,
        response_format: 'b64_json',
        style: 'natural',
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify(imageParams),
        });

        const imagesResponse = await response.json();

        const base64Image = imagesResponse.data[0].b64_json;
        return `data:image/png;base64,${base64Image}`;
    } catch (error) {
        console.error(`Error generating image: ${error}`);
    }
};
