import React from 'react';
import './Header.css';
import { Container, Nav, Navbar, Dropdown, Image } from 'react-bootstrap';

const Header = ({ handleLogout, isLoggedIn, userPicture }) => {
    return (
        <Navbar sticky="top" expands="lg" bg="primary" data-bs-theme="dark">
            <Container fluid className="m-1">
                <Navbar.Brand>Pickford Studio</Navbar.Brand>
                {isLoggedIn && (
                    <>
                        <Nav className="me-auto">
                            <Nav.Link href="/projects">Projects</Nav.Link>
                            <Nav.Link disabled>Community</Nav.Link>
                        </Nav>
                        <Nav className="ms-auto">
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <Image src={userPicture} roundedCircle width="45" height="45" alt="Account" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </>
                )}
            </Container>
        </Navbar>
    );
};

export default Header;
