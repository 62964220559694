import Cookies from 'js-cookie';
import { getUserEmail } from './users';

const DATABASE_API_URL = process.env.REACT_APP_DATABASE_API_URL;

export const getProjects = async () => {
    try {
        const userEmail = await getUserEmail();

        // Call the get_user_projects endpoint
        const response = await fetch(`${DATABASE_API_URL}/get_user_projects?user_email=${userEmail}`);

        if (!response.ok) {
            throw new Error('Failed to fetch projects');
        }

        const projects = await response.json();
        const newProjects = projects.map(proj => ({
            id: proj.project_id,
            title: proj.title,
            image: proj.image || '/default-project-header.png',
            description: proj.description || 'Description will be generated for you.',
        }));

        return newProjects;
    } catch (error) {
        console.error('Error getting projects:', error);
        throw error;
    }
};

export const getProject = async projectId => {
    Cookies.set('project_id', projectId);
    try {
        const userEmail = await getUserEmail();

        // Call the get_project endpoint
        const response = await fetch(`${DATABASE_API_URL}/get_project?user_email=${userEmail}&project_id=${projectId}`);

        if (!response.ok) {
            throw new Error('Failed to fetch project');
        }

        const project = await response.json();

        // Convert Base64 strings to Blob URLs if needed
        const projectWithBlobUrls = await _convertCharacterImagesToBlobUrls(project);

        return projectWithBlobUrls;
    } catch (error) {
        console.error('Error fetching project:', error);
        throw error;
    }
};

export const createNewProject = async (setError, navigate) => {
    const userEmail = await getUserEmail(); // Ensure you await the promise
    const projectData = {
        title: 'New Project',
        description: 'Description will be generated for you.',
        image: '/default-project-header.png',
        dss: {
            basic_prompt: '',
            advanced_prompt: {
                genre: 'Action',
                tone: 5,
                plot: '',
                setting: '',
                cinematography: 'Low-key',
                theme: 'Existentialism',
                pace: 5,
                dialog_style: 'Modern English',
                ending: '',
                scene_count: 1,
            },
        },
        script: { scenes: [] },
        characters: [],
        sounds: [],
        sets: [],
        cameras: [],
    };
    try {
        const response = await fetch(`${DATABASE_API_URL}/create_project?user_email=${userEmail}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(projectData),
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            console.error(`HTTP error! status: ${response.status}`, errorDetail);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        Cookies.set('project_id', result.project_id);
        navigate(`/project/${result.project_id}`);
    } catch (error) {
        console.error('Error saving project:', error);
        setError(`Error saving project: ${error.message}`);
    }
};

export const updateProject = async ({ setError, setSaveStatus, data }) => {
    const userEmail = await getUserEmail(); // Ensure you await the promise
    const updateProjectId = Cookies.get('project_id');
    const updateProjectData = {
        project_id: updateProjectId,
        title: data.title,
        description: data.description || null,
        image: data.image || null,
        dss: data.dss,
        script: data.script,
        characters: data.characters,
        sounds: data.sounds || [],
        sets: data.sets || [],
        cameras: data.cameras || [],
    };
    try {
        // Fetch and replace the blob URLs with actual blobs
        const projectWithBlobs = await _getCharacterFileBlobs(updateProjectData);

        const response = await fetch(`${DATABASE_API_URL}/update_project?user_email=${userEmail}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(projectWithBlobs),
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            console.error(`HTTP error! status: ${response.status}`, errorDetail);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (response.ok) {
            setSaveStatus('Saved');
        }

        const result = await response.json();
    } catch (error) {
        console.error('Error saving project:', error);
        setError(`Error saving project: ${error.message}`);
    }
};

async function _getCharacterFileBlobs(project) {
    // Create a deep copy of the project object
    const projectCopy = JSON.parse(JSON.stringify(project));

    // Define the list of potential file attributes
    const fileAttributes = ['image_2d', 'image_3d', 'obj_file', 'mtl_file', 'img_file', 'rigged_file'];

    // Function to fetch a blob from a URL
    async function fetchBlob(url) {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch ${url}`);
        }
        return response.blob();
    }

    // Loop through each character in the project copy
    for (const character of projectCopy.characters) {
        for (const attribute of fileAttributes) {
            if (character[attribute]) {
                try {
                    // Fetch the blob and replace the URL with the blob
                    const blob = await fetchBlob(character[attribute]);
                    const base64Blob = await _blobToBase64(blob);
                    character[attribute] = base64Blob;
                } catch (error) {
                    console.error(`Error fetching blob for ${attribute}:`, error);
                }
            }
        }
    }

    return projectCopy;
}

async function _convertCharacterImagesToBlobUrls(project) {
    // Create a deep copy of the project object
    const projectCopy = JSON.parse(JSON.stringify(project));

    // Define the list of potential file attributes
    const fileAttributes = ['image_2d', 'image_3d', 'obj_file', 'mtl_file', 'img_file', 'rigged_file'];

    // Function to create a Blob URL from a Base64 string
    function createBlobUrl(base64String) {
        const blob = _base64ToBlob(base64String);
        return URL.createObjectURL(blob);
    }

    // Loop through each character in the project copy
    for (const character of projectCopy.characters) {
        for (const attribute of fileAttributes) {
            if (character[attribute]) {
                try {
                    // Convert the Base64 string to a Blob URL
                    const blobUrl = createBlobUrl(character[attribute]);
                    character[attribute] = blobUrl;
                } catch (error) {
                    console.error(`Error converting Base64 to Blob URL for ${attribute}:`, error);
                }
            }
        }
    }

    return projectCopy;
}

function _blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

function _base64ToBlob(base64) {
    const binary = atob(base64.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
}
