import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './StudioView.css';

import TitleBar from '../TitleBar';
import SelectionBar from '../SelectionBar';

import DSSView from '../DSS/DSSView';
import ScriptView from '../Script/ScriptView';
import CharactersView from '../Characters/CharactersView';
import SoundsView from '../Sounds/SoundsView';
import SetsView from '../Sets/SetsView';
import CamerasView from '../Cameras/CamerasView';

import LoadingInformation from '../Modals/LoadingInformation';
import ErrorInformation from '../Modals/ErrorInformation';
import ViewStory from '../Modals/ViewStory';

import { generateBasicPrompt, generateAdvancedPrompt, text2script, generateStoryDescription, generateStoryImage } from '../../utils/text2script';
import { postProcessScript } from '../../utils/structureScript';
import { renderVideo } from '../../utils/renderVideo';
import { parseCharacters } from '../../utils/parseCharacters';
import { updateProject, getProject } from '../../utils/projects';
import PickfordPackage from '../../utils/pickfordPackage';

const StudioView = () => {
    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('DSS');
    const [dssInputMode, setDSSInputMode] = useState('Basic');
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [error, setError] = useState('');
    const [videoUri, setVideoUri] = useState('');
    const [zipUrl, setZipUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [saveStatus, setSaveStatus] = useState('Saved');

    const [data, setData] = useState({
        title: 'Studio Example',
        dss: {
            basic_prompt: '',
            advanced_prompt: {
                genre: 'Action',
                tone: 5,
                plot: '',
                setting: '',
                cinematography: 'Low-key',
                theme: 'Exestentialism',
                pace: 5,
                dialog_style: 'Modern English',
                ending: '',
                scene_count: 1, // TODO: Move one level up
            },
        },
        script: {
            scenes: [],
        },
        characters: [],
        sounds: [],
        sets: [],
        cameras: [],
    });

    useEffect(() => {
        const fetchProjectData = async () => {
            setLoading(true);
            setLoadingText('Loading project data...');
            try {
                const projectData = await getProject(id);
                setData(projectData);
            } catch (error) {
                setError(`Error fetching project: ${error.message}`);
            } finally {
                setLoading(false);
                setLoadingText('');
            }
        };

        fetchProjectData();
    }, [id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('Auto-saving project...');
            updateProject({ setError, setSaveStatus, data });
        }, 5000); // 5 seconds

        return () => clearTimeout(timer); // Clear the timer if data changes
    }, [data]); // Run this effect whenever `data` changes

    useEffect(() => {
        if (error.length > 1) {
            setTimeout(() => {
                setError('');
            }, 10000);
        }
    }, [error]);

    const updateData = (view, newData) => {
        setData(prevData => ({
            ...prevData,
            [view]: newData,
        }));
        setSaveStatus('Unsaved');
    };

    const generateScript = async () => {
        let messages;
        if (dssInputMode === 'Basic') {
            messages = generateBasicPrompt(data.dss.basic_prompt);
        } else {
            const { genre, tone, plot, setting, theme, pace, dialog_style, ending } = data.dss.advanced_prompt;
            messages = generateAdvancedPrompt(genre, tone, plot, setting, theme, pace, dialog_style, ending);
        }

        // Set loading state
        setLoading(true);

        try {
            // Call the text2script function to generate the script
            const scenes = await text2script(messages, data.dss.advanced_prompt.scene_count, setLoadingText);
            const mappedScenes = scenes.map(scene => ({ scene_text: scene, scene_id: null }));
            updateData('script', { scenes: mappedScenes });
            setActiveTab('Script');

            // Set the project cover image and description
            // const description = await generateStoryDescription(messages);
            // updateData('Description', description);
            // const coverImage = await generateStoryImage(description);
            // updateData('Image', coverImage);
        } finally {
            setLoading(false);
            setLoadingText('');
        }
    };

    const parseCharactersFromScript = async () => {
        try {
            setLoading(true);
            setLoadingText('Parsing characters...');

            const script = data.script.scenes.map(scene => scene.scene_text).join('\n');
            const characters = await parseCharacters(script, setError);

            updateData('characters', characters);
        } finally {
            // Unset loading state
            setLoading(false);
            setLoadingText('');
            setActiveTab('Characters');
        }
    };

    const createStory = async () => {
        const packageInstance = new PickfordPackage();

        setLoading(true);
        setLoadingText('Creating package...');

        const { scriptJsonWithCameraShots, scriptText } = await postProcessScript(data, setError);

        packageInstance.addScriptText(scriptText);
        packageInstance.addScriptJson(scriptJsonWithCameraShots);

        for (const character of data.characters) {
            if (character.rigged_file) {
                await packageInstance.addCharacter(character.rigged_file, character.name);
            }
        }

        const zipBlob = await packageInstance.compressPackage();
        const zipUrl = URL.createObjectURL(zipBlob);

        setLoadingText('Rendering story...');
        try {
            const videoUri = await renderVideo(zipBlob, setLoadingText, setError, setVideoUri);
            setVideoUri(videoUri + `?t=${new Date().getTime()}`); // Append timestamp to URI to force refresh
        } finally {
            setLoading(false);
            setLoadingText('');
        }

        setZipUrl(zipUrl); // If you want to use this URL for downloading or other purposes
        setShowModal(true);

        setLoading(false);
        setLoadingText('');
    };

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'DSS':
                return <DSSView dssInputMode={dssInputMode} setDSSInputMode={setDSSInputMode} data={data.dss} updateData={newData => updateData('dss', newData)} generateScript={generateScript} />;
            case 'Script':
                return <ScriptView data={data.script} updateData={newData => updateData('script', newData)} parseCharacters={parseCharactersFromScript} />;
            case 'Characters':
                return <CharactersView data={data.characters} updateData={newData => updateData('characters', newData)} setLoading={setLoading} setLoadingText={setLoadingText} setError={setError} />;
            case 'Sounds':
                return <SoundsView />;
            case 'Sets':
                return <SetsView />;
            case 'Cameras':
                return <CamerasView />;
            default:
                return <DSSView dssInputMode={dssInputMode} setDSSInputMode={setDSSInputMode} data={data.dss} updateData={newData => updateData('dss', newData)} generateScript={generateScript} />;
        }
    };

    return (
        <>
            <TitleBar data={data.title} updateData={newData => updateData('title', newData)} createStory={createStory} saveStatus={saveStatus} />
            {renderActiveTab()}
            <SelectionBar activeTab={activeTab} setActiveTab={setActiveTab} />
            {loading && <LoadingInformation text={loadingText} />}
            {error && <ErrorInformation errorText={error} />}
            <ViewStory showModal={showModal} zipUrl={zipUrl} videoUri={videoUri} setVideoUri={setVideoUri} setShowModal={setShowModal} />
        </>
    );
};

export default StudioView;
