import Cookies from 'js-cookie';

const DATABASE_API_URL = process.env.REACT_APP_DATABASE_API_URL;

export const getUser = async userEmail => {
    const response = await fetch(`${DATABASE_API_URL}/get_user?user_email=${userEmail}`, {
        method: 'GET',
    });

    if (!response.ok) {
        return null; // User not found
    }

    const getResult = await response.json();
    return getResult;
};

export const createUser = async userEmail => {
    const formData = new FormData();
    formData.append('user_email', userEmail);

    const response = await fetch(`${DATABASE_API_URL}/create_user`, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const saveResult = await response.json();
    return saveResult;
};

export const getUserEmail = async () => {
    try {
        const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch user info');
        }
        const userData = await response.json();
        return userData.email;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};
