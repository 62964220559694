import React from 'react';
import './Basic.css';
import Form from 'react-bootstrap/Form';

const Basic = ({ data, updateData }) => {
    const handleChange = event => {
        updateData(event.target.value);
    };

    return (
        <Form>
            <Form.Group className="m-3">
                <Form.Label>Story Description</Form.Label>
                <Form.Control as="textarea" rows="25" value={data} onChange={handleChange} />
            </Form.Group>
        </Form>
    );
};

export default Basic;
