/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import './CharactersView.css';
import { textToImage, imageTo3D, rigCharacter } from '../../utils/generateCharacter';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';
import { OrbitControls } from 'three/examples/jsm/Addons.js';
import { Form, Container, Row, Col, Button, CloseButton, Badge, Spinner } from 'react-bootstrap';
import { BsUpload, BsDownload } from 'react-icons/bs';

const CharactersView = ({ data, updateData, setLoading, setLoadingText, setError }) => {
    const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(0);
    const [rigLoading, setRigLoading] = useState(false);
    const [rigError, setRigError] = useState(false);
    const threeContainerRef = useRef(null);
    const controlsRef = useRef(null);
    const sceneRef = useRef(null);

    const handleCharacterChange = index => {
        setSelectedCharacterIndex(index);
    };

    const removeCharacter = () => {
        const updatedCharacters = data.filter((_, i) => i !== selectedCharacterIndex);
        updateData(updatedCharacters);
        if (selectedCharacterIndex === selectedCharacterIndex && updatedCharacters.length > 0) {
            setSelectedCharacterIndex(Math.max(0, selectedCharacterIndex - 1));
        } else if (selectedCharacterIndex.length === 0) {
            setSelectedCharacterIndex(0);
        }
    };

    const handleChange = (field, value) => {
        const newCharacters = [...data];
        newCharacters[selectedCharacterIndex] = {
            ...newCharacters[selectedCharacterIndex],
            [field]: value,
        };
        updateData(newCharacters);
    };

    const addCharacter = () => {
        const newCharacter = { name: `Character ${data.length + 1}`, visual_description: 'A woman in an astronaut suit. It is faded and dusty with a logo on the chest.', personality_summary: '', motivation: '' };
        const newCharacters = [...data, newCharacter];
        updateData(newCharacters);
        setSelectedCharacterIndex(newCharacters.length - 1);
    };

    const handleNameChange = event => {
        handleChange('name', event.target.innerText);
    };

    const handleGenerate2DImage = async () => {
        const characterId = selectedCharacterIndex;
        const prompt = `
        A SINGLE view of a humanoid character. Front facing, t-posed, legs shoulder length apart, centred on an all white background with their full body visible. Ignore any tools or extras in the 'Subject'.
        Subject: ${data[characterId].visual_description}
        Style: Animated
        `;
        try {
            const image2D = await textToImage(prompt, setLoading, setLoadingText, setError);
            updateCharacterImage(characterId, { image_2d: image2D });
        } catch (error) {
            setError(`Error generating 2D image: ${error}`);
        }
    };

    const handleGenerate3DImage = async () => {
        const characterId = selectedCharacterIndex;
        const selectedCharacter = data[characterId];

        if (!selectedCharacter.image_2d) {
            try {
                const prompt = `
                A SINGLE view of a humanoid character. Front facing, t-posed, legs shoulder length apart, centred on an all white background with their full body visible. Ignore any tools or extras in the 'Subject'.
                Subject: ${data[characterId].visual_description}
                Style: Animated
                `;
                const image2D = await textToImage(prompt, setLoading, setLoadingText, setError);
                updateCharacterImage(characterId, { image_2d: image2D });

                // Now generate the 3D image using the newly generated 2D image
                const imageFile = await fetch(image2D).then(res => res.blob());
                const { objPath, objPathName, mtlPath, mtlPathName, imgPath, imgPathName, glbPath } = await imageTo3D(imageFile, setLoading, setLoadingText, setError);

                // Update 3D image separately after 2D image update
                updateCharacterImage(characterId, {
                    image_2d: image2D,
                    image_3d: glbPath,
                    obj_file: objPath,
                    obj_file_name: objPathName,
                    mtl_file: mtlPath,
                    mtl_file_name: mtlPathName,
                    img_file: imgPath,
                    img_file_name: imgPathName,
                });
            } catch (error) {
                setError(`Error generating images: ${error}`);
                setTimeout(() => {
                    setError('');
                }, 10000);
            }
        } else {
            try {
                const imageFile = await fetch(selectedCharacter.image_2d).then(res => res.blob());
                const { objPath, objPathName, mtlPath, mtlPathName, imgPath, imgPathName, glbPath } = await imageTo3D(imageFile, setLoading, setLoadingText, setError);
                updateCharacterImage(characterId, {
                    image_3d: glbPath,
                    obj_file: objPath,
                    obj_file_name: objPathName,
                    mtl_file: mtlPath,
                    mtl_file_name: mtlPathName,
                    img_file: imgPath,
                    img_file_name: imgPathName,
                });
            } catch (error) {
                setError(`Error generating 3D image: ${error}`);
                setTimeout(() => {
                    setError('');
                }, 10000);
            }
        }
    };

    const handleGenerateRig = async () => {
        if (selectedCharacter.obj_file && selectedCharacter.mtl_file && selectedCharacter.img_file && !selectedCharacter.rigged_file) {
            setRigLoading(true);
            const riggedFilePath = await rigCharacter(
                selectedCharacter.obj_file,
                selectedCharacter.obj_file_name,
                selectedCharacter.mtl_file,
                selectedCharacter.mtl_file_name,
                selectedCharacter.img_file,
                selectedCharacter.img_file_name,
                setError
            );
            if (riggedFilePath) {
                updateCharacterImage(selectedCharacterIndex, { rigged_file: riggedFilePath });
                setRigError(false);
            } else {
                setRigError(true);
            }
            setRigLoading(false);
        }
    };

    const updateCharacterImage = (characterId, images) => {
        const newCharacters = [...data];
        newCharacters[characterId] = {
            ...newCharacters[characterId],
            ...images,
        };
        updateData(newCharacters);
    };

    useEffect(() => {
        if (selectedCharacter && selectedCharacter.obj_file && selectedCharacter.mtl_file && selectedCharacter.img_file && !selectedCharacter.rigged_file) {
            handleGenerateRig();
        }
    }, [data]);

    const handleDownload = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUpload2DImage = async event => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            updateCharacterImage(selectedCharacterIndex, { image_2d: imageURL });
        }
    };

    const handleUpload3DImage = async event => {
        const file = event.target.files[0];
        if (file) {
            const glbURL = URL.createObjectURL(file);
            updateCharacterImage(selectedCharacterIndex, { image_3d: glbURL });
        }
    };

    const selectedCharacter = data[selectedCharacterIndex];

    const init3DScene = glbFile => {
        if (!threeContainerRef.current) return;

        // Clear the previous 3D scene if any
        if (sceneRef.current) {
            sceneRef.current.clear();
            while (threeContainerRef.current.firstChild) {
                threeContainerRef.current.removeChild(threeContainerRef.current.firstChild);
            }
        }

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, threeContainerRef.current.clientWidth / threeContainerRef.current.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });

        renderer.setSize(threeContainerRef.current.clientWidth, threeContainerRef.current.clientHeight);
        renderer.setClearColor(0x000000, 0);
        threeContainerRef.current.appendChild(renderer.domElement);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);

        // Additional point lights for more brightness
        const pointLight1 = new THREE.PointLight(0xffffff, 1);
        pointLight1.position.set(5, 5, 5);
        scene.add(pointLight1);

        const pointLight2 = new THREE.PointLight(0xffffff, 1);
        pointLight2.position.set(-5, -5, -5);
        scene.add(pointLight2);

        const loader = new GLTFLoader();
        loader.load(
            glbFile,
            gltf => {
                const model = gltf.scene;
                scene.add(model);
                model.position.set(0, 0, 0);

                // Set the scale of the model
                const scale = 1.5;
                model.scale.set(scale, scale, scale);

                // Rotate the model
                model.rotation.y = Math.PI;

                // Adjust the camera position
                const box = new THREE.Box3().setFromObject(model);
                const size = box.getSize(new THREE.Vector3()).length();
                const center = box.getCenter(new THREE.Vector3());
                camera.position.set(center.x, center.y, size * 0.6);

                const controls = new OrbitControls(camera, renderer.domElement);
                controls.target.copy(center);
                controls.update();
                controlsRef.current = controls;

                const animate = () => {
                    requestAnimationFrame(animate);
                    controls.update();
                    renderer.render(scene, camera);
                };

                animate();
            },
            undefined,
            error => {
                setError(`Error loading glb file: ${error}`);
            }
        );

        sceneRef.current = scene;
    };

    useEffect(() => {
        if (selectedCharacter && selectedCharacter.image_3d) {
            // Clear the previous 3D scene if any
            if (threeContainerRef.current) {
                while (threeContainerRef.current.firstChild) {
                    threeContainerRef.current.removeChild(threeContainerRef.current.firstChild);
                }
            }
            init3DScene(selectedCharacter.image_3d);
        }
    }, [selectedCharacter]);

    return (
        <div className="characters-view">
            {data.length > 0 && (
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={8} md={8} className="border p-4 shadow rounded position-relative">
                            <div className="character-name" contentEditable suppressContentEditableWarning onBlur={handleNameChange}>
                                {selectedCharacter.name}
                            </div>
                            <CloseButton className="position-absolute" style={{ top: '10px', right: '10px' }} onClick={removeCharacter} />
                            <Row className="mb-3">
                                <Col>
                                    <div className="position-relative border rounded p-2" style={{ minHeight: '268px', backgroundColor: '#e9ecef' }}>
                                        {selectedCharacter.image_2d ? (
                                            <img src={selectedCharacter.image_2d} alt="2D Image" className="img-fluid rounded" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
                                        ) : (
                                            <span>2D Image</span>
                                        )}
                                        <label htmlFor="upload2D" className="btn btn-light position-absolute" style={{ top: '10px', right: '10px' }}>
                                            <BsUpload />
                                        </label>
                                        <input id="upload2D" accept="image/*" type="file" className="d-none" onChange={handleUpload2DImage} />
                                        <Button variant="light" className="position-absolute" style={{ top: '50px', right: '10px' }} onClick={() => handleDownload(selectedCharacter.image_2d, `${selectedCharacter.name}_2D.png`)}>
                                            <BsDownload />
                                        </Button>
                                    </div>
                                    <Button variant="secondary" className="mt-2 w-100" onClick={handleGenerate2DImage}>
                                        Generate
                                    </Button>
                                </Col>
                                <Col>
                                    <div className="position-relative border rounded p-2" style={{ minHeight: '268px', backgroundColor: '#e9ecef' }}>
                                        {selectedCharacter.image_3d ? <div ref={threeContainerRef} className="three-container"></div> : <span>3D Image</span>}
                                        <label htmlFor="upload3D" className="btn btn-light position-absolute" style={{ top: '10px', right: '10px' }}>
                                            <BsUpload />
                                        </label>
                                        <input id="upload3D" type="file" accept=".glb" className="d-none" onChange={handleUpload3DImage} />
                                        <Button variant="light" className="position-absolute" style={{ top: '50px', right: '10px' }} onClick={() => handleDownload(selectedCharacter.image_3d, `${selectedCharacter.name}_3D.glb`)}>
                                            <BsDownload />
                                        </Button>
                                        {rigLoading && (
                                            <Badge bg="warning" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                                Rigging
                                            </Badge>
                                        )}
                                        {selectedCharacter.image_3d && !selectedCharacter.rigged_file && !rigLoading && !rigError && (
                                            <Badge bg="secondary" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                                Not Rigged
                                            </Badge>
                                        )}
                                        {selectedCharacter.rigged_file && !rigLoading && (
                                            <Badge bg="success" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                                Rigged
                                            </Badge>
                                        )}
                                        {rigError && !rigLoading && (
                                            <Badge bg="danger" className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
                                                Rig Error
                                            </Badge>
                                        )}
                                    </div>
                                    <Col className="d-flex">
                                        <Button
                                            variant="secondary"
                                            className={rigError ? 'mt-2 w-50 me-1' : 'mt-2 w-100'}
                                            onClick={() => {
                                                handleGenerate3DImage();
                                                // handleGenerateRig();
                                            }}
                                        >
                                            Generate
                                        </Button>
                                        {rigError && (
                                            <Button variant="secondary" className="mt-2 w-50" onClick={handleGenerateRig}>
                                                Rig
                                            </Button>
                                        )}
                                    </Col>
                                </Col>
                            </Row>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Visual Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={selectedCharacter.visual_description} onChange={e => handleChange('visual_description', e.target.value)} placeholder="Information on what they look like." />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Personality Summary</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={selectedCharacter.personality_summary} onChange={e => handleChange('personality_summary', e.target.value)} placeholder="Information on their personality." />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Motivation</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={selectedCharacter.motivation} onChange={e => handleChange('motivation', e.target.value)} placeholder="What is their motivation?" />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3">
                        <Col xs={14} md={14} className="d-flex justify-content-center">
                            <div className="p-2 w-100" style={{ overflowX: 'auto', border: '1px solid #dee2e6', borderRadius: '0.25rem' }}>
                                <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
                                    {data.map((character, index) => (
                                        <Button
                                            key={index}
                                            variant="outline-primary"
                                            className={selectedCharacterIndex === index ? 'active' : ''}
                                            onClick={() => handleCharacterChange(index)}
                                            style={{ flex: '0 0 auto', marginRight: '10px' }}
                                        >
                                            {character.name}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
            <Container>
                <Row className="mt-3">
                    <Col className="justify-content-start">
                        <Button variant="primary" onClick={addCharacter}>
                            Add Character
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CharactersView;
