import JSZip from 'jszip';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const IMAGE_TO_3D_URL = `${process.env.REACT_APP_MAIN_API_URL}/image_to_3d`;
const RIGGING_URL = `${process.env.REACT_APP_MAIN_API_URL}/rig_character`;

// Function to generate 2D image from text
export const textToImage = async (prompt, setLoading, setLoadingText, setError) => {
    const apiUrl = 'https://api.openai.com/v1/images/generations'; // Replace with your actual API URL

    const imageParams = {
        model: 'dall-e-3',
        prompt: prompt,
        size: '1024x1024',
        quality: 'standard',
        n: 1,
        response_format: 'b64_json',
        style: 'natural',
    };

    try {
        setLoading(true);
        setLoadingText('Generating 2D image...');

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify(imageParams),
        });

        const imagesResponse = await response.json();

        // Decode the base64 image data
        const base64Image = imagesResponse.data[0].b64_json;
        return `data:image/png;base64,${base64Image}`;
    } catch (error) {
        setError(`Error generating image: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    } finally {
        setLoading(false);
        setLoadingText('');
    }
};

// Function to generate 3D image from 2D image
export const imageTo3D = async (imageFile, setLoading, setLoadingText, setError) => {
    const formData = new FormData();
    formData.append('image_file', imageFile);

    try {
        setLoading(true);
        setLoadingText('Generating 3D image...');

        const response = await fetch(IMAGE_TO_3D_URL, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            setError('Failed to generate 3D model.');
            setTimeout(() => {
                setError('');
            }, 10000);
        }

        const contentDisposition = response.headers.get('Content-Disposition');
        const zipFilename = contentDisposition
            .split('filename=')[1]
            .trim()
            .replace(/"/g, '');
        const blob = await response.blob();

        const zipFile = new File([blob], zipFilename);
        const extractedFiles = await extractFilesFromZip(zipFile);

        const { objPath, objPathName, mtlPath, mtlPathName, imgPath, imgPathName, glbPath, glbPathName } = extractedFiles;
        return { objPath, objPathName, mtlPath, mtlPathName, imgPath, imgPathName, glbPath, glbPathName };
    } catch (error) {
        setError(`Error generating 3D model: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    } finally {
        setLoading(false);
        setLoadingText('');
    }
};

export const rigCharacter = async (objFilePath, objFilePathName, mtlFilePath, mtlFilePathName, imgFilePath, imgFilePathName, setError) => {
    const formData = new FormData();

    try {
        const objFile = await fetch(objFilePath).then(res => res.blob());
        const mtlFile = await fetch(mtlFilePath).then(res => res.blob());
        const imgFile = await fetch(imgFilePath).then(res => res.blob());

        formData.append('obj_file', objFile, objFilePathName);
        formData.append('mtl_file', mtlFile, mtlFilePathName);
        formData.append('img_file', imgFile, imgFilePathName);

        const response = await fetch(RIGGING_URL, {
            method: 'POST',
            body: formData,
        });

        if (response.status === 200) {
            const data = await response.json();
            const modelData = data.model_file;

            if (modelData) {
                const decodedModelData = atob(modelData);
                const byteNumbers = new Array(decodedModelData.length);
                for (let i = 0; i < decodedModelData.length; i++) {
                    byteNumbers[i] = decodedModelData.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const modelBlob = new Blob([byteArray], { type: 'model/glb' });

                const modelURL = URL.createObjectURL(modelBlob);

                return modelURL;
            } else {
                setError('Error: The API did not return file data correctly.');
                setTimeout(() => {
                    setError('');
                }, 10000);
                return null;
            }
        } else {
            setError(`Error with request: Status code ${response.status}`);
            setTimeout(() => {
                setError('');
            }, 10000);
            return null;
        }
    } catch (error) {
        setError(`Error with file upload: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};

const extractFilesFromZip = async zipFile => {
    // Implement the extraction logic using a library like jszip
    const zip = await JSZip.loadAsync(zipFile);

    const objFile = zip.file(/\.obj$/i)[0];
    const mtlFile = zip.file(/\.mtl$/i)[0];
    const imgFile = zip.file(/\.png$/i)[0];
    const glbFile = zip.file(/\.glb$/i)[0];

    const objPathName = objFile.name;
    const mtlPathName = mtlFile.name;
    const imgPathName = imgFile.name;
    const glbPathName = glbFile.name;

    const objPath = URL.createObjectURL(await objFile.async('blob'));
    const mtlPath = URL.createObjectURL(await mtlFile.async('blob'));
    const imgPath = URL.createObjectURL(await imgFile.async('blob'));
    const glbPath = URL.createObjectURL(await glbFile.async('blob'));

    return { objPath, objPathName, mtlPath, mtlPathName, imgPath, imgPathName, glbPath, glbPathName };
};
