const UNITY_API_URL = `${process.env.REACT_APP_MAIN_API_URL}/render_story`;

export const renderVideo = async (zipBlob, setLoadingText, setError) => {
    const url = UNITY_API_URL;

    const formData = new FormData();
    formData.append('pickford_package_file', zipBlob, 'test.zip');
    setLoadingText('Rendering story...');

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data.video_uri;
    } catch (error) {
        setError('Failed to render the video. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return null;
    }
};
