import React, { useState, useEffect } from 'react';
import './ProjectsView.css';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getProjects, createNewProject } from '../../utils/projects';

// todo: Add loading the users project here
// Add saving the users projects here

const ProjectsView = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectsData = await getProjects();
                setProjects(projectsData);
            } catch (error) {
                setError(`Error fetching projects: ${error.message}`);
            }
        };
        fetchProjects();
    }, []);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleEditClick = projectId => {
        navigate(`/project/${projectId}`);
    };

    const handleNewProjectClick = async () => {
        await createNewProject(setError, navigate);
    };

    const filteredProjects = projects.filter(project => project.title.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <Container className="container-full-height mt-4 p-3 border shadow-sm rounded bg-white">
            <Row className="mb-4">
                <Col>
                    <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
                </Col>
                <Col xs="auto">
                    <Button onClick={handleNewProjectClick}>New Project</Button>
                </Col>
            </Row>
            <div className="content">
                <Row>
                    {filteredProjects.map(project => (
                        <Col key={project.id} sm={6} md={4} lg={3} className="mb-4 project-col">
                            <Card className="project-card">
                                <Card.Img variant="top" src={project.image} className="project-image" />
                                <Button variant="secondary" className="edit-button" size="sm" onClick={() => handleEditClick(project.id)}>
                                    Edit
                                </Button>
                                <Card.Body>
                                    <Card.Title className="projects-project-title">{project.title}</Card.Title>
                                    <Card.Text className="project-description">{project.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
};

export default ProjectsView;
