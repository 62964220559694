import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { createUser, getUser } from '../../utils/users';

const CallbackComponent = ({ handleLogin }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleUserAuthentication = async () => {
            // Extract access token from URL fragment
            const accessTokenRegex = /access_token=([^&]+)/;
            const expiresInRegex = /expires_in=([^&]+)/;
            const accessTokenMatch = window.location.hash.match(accessTokenRegex);
            const expiresInMatch = window.location.hash.match(expiresInRegex);

            if (accessTokenMatch && expiresInMatch) {
                const accessToken = accessTokenMatch[1];
                const expiresInSeconds = parseInt(expiresInMatch[1], 10);
                const expirationDate = new Date(new Date().getTime() + expiresInSeconds * 1000);

                try {
                    const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch user info');
                    }

                    const userData = await response.json();

                    let user = await getUser(userData.email);
                    if (!user) {
                        user = await createUser(userData.email);
                    }

                    if (user) {
                        Cookies.set('access_token', accessToken, { expires: expirationDate }); // Set expiration to match token
                        Cookies.set('user_email', userData.email, { expires: expirationDate });
                        Cookies.set('user_picture', userData.picture, { expires: expirationDate });
                        handleLogin(userData.picture); // Call handleLogin to update the parent component state
                        navigate('/projects'); // Redirect to a protected route
                    } else {
                        throw new Error('Failed to create user');
                    }
                } catch (error) {
                    console.error('Error fetching user info or creating user:', error);
                    navigate('/'); // Redirect to login if there's an error
                }
            } else {
                navigate('/'); // Redirect to login if no token found
            }
        };

        handleUserAuthentication();
    }, [handleLogin, navigate]);

    return null; // This component doesn't render anything visible
};

export default CallbackComponent;
